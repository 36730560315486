import { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import QuestionSet from './QuestionSet'
import { AccountContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
import { getAuthedRequest } from 'utils/requests'
import Modal from 'components/Modal'

const Selector = () => {
  const { t } = useTranslation()
  const { requestType } = useParams()
  
  let [showRequestSubMes, setShowRequestSubMes] = useState(false)

  const { allowedRequestTypes, updateCurrentRequest } = useContext(AccountContext)

  const primaryLabel = () => {
    if (requestType === 'question') {
      return t('What kind of question are you asking?')
    } else if (requestType === 'quote') {
      return t('What would you like a quote for?')
    }
    return t('What change would you like to make?')
  }

  useEffect(() => {
    updateCurrentRequest({ requestType: requestType, lineitemUrl: null })
  }, []) // eslint-disable-line
  
    useEffect(() => {
      getAuthedRequest('/api/lineitems/request-past-week/')
        .then(res => {
          setShowRequestSubMes(res.request_past_week)
        })
    }, [])

  return (
    <div>
      <div className="field radio stacked">
        <label className="main-label">
          {primaryLabel()}
        </label>
        <div className="field-wrapper">
          <div className="choices">
            <QuestionSet questions={allowedRequestTypes()[requestType]} />
          </div>
        </div>
      </div>
      <Modal isOpen={showRequestSubMes} onClose={() => setShowRequestSubMes(false)}>
        <label className="main-label">
          {t('We noticed you have submitted an inquiry within the last 7 days. If this is a new request, please continue. If this is regarding the request you recently submitted, please be advised that we are working on it and will send you an email once your request is completed. If you need immediate assistance please book an appointment or chat with us.')}
        </label>
      </Modal>
    </div>
  )
}

export default Selector