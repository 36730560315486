import React, { useContext, useState } from 'react'
import Radio from 'forms/fields/Radio'
import Text from 'forms/fields/Text'
import Select from 'forms/fields/Select'
import { FormContext } from 'utils/context'
import { vehicleRemoveReasons, removeOrReduce, vehicleReplaceReasons } from 'forms/utils/vars'
import { useTranslation } from 'react-i18next'
import ReduceReason from 'forms/fieldsets/vehicle/ReduceReason'
import CoverageWarning from 'forms/fieldsets/vehicle/CoverageWarning'
import CoverageReview from 'forms/fieldsets/vehicle/CoverageReview'
import Acknowledgement from '../general/Acknowledgement'

const RemoveReason = () => {
  
  const { t } = useTranslation()
  const { data, setFieldValue, clearAllErrors } = useContext(FormContext)
  
  const [helpTextCopy, sethelpTextCopy] = useState("")

  let showOtherText = data['remove_reason'] === 'other'
  let showMovingTo = data['remove_reason'] === 'moving_insurance'
  const removeReasonStorage = data['remove_reason'] === 'storage'
  const reduceCoverageSelected = data['reduce_or_remove'] === 'reduce_coverage'
  const isSelling = data['remove_reason'] === 'selling' || data['remove_reason'] === 'sell'

  const reasonChange = value => {
    if (value !== 'moving_insurance') {
      setFieldValue('moving_to_carrier', null)
      setFieldValue('moving_to_policy_number', null)
      clearAllErrors()
    }
    if (value === 'storage') {
      sethelpTextCopy(t("By removing this vehicle you will have no coverage while it is in storage. There is an option to reduce coverage, this option provides the following coverages while the vehicle is in storage: Fire, Theft, Vandalism and Glass breakage while the vehicle is parked."))
    } else {
      sethelpTextCopy("")
      setFieldValue('reduce_or_remove', null)
      clearAllErrors()
    } 
    if (value !== 'other') {
      setFieldValue('remove_reason_other', null)
      clearAllErrors()
    }
  }

  return (
    <fieldset>
      <Radio
        name="remove_reason"
        label={t("Why are you removing this vehicle?")}
        choices={data.type === 'remove_vehicle' ? vehicleRemoveReasons : vehicleReplaceReasons}
        ChangeCallback={reasonChange}
        HelpCopy={helpTextCopy}
      />
      {isSelling &&
        <Acknowledgement
          fieldName="selling_warning"
          description={t("If this vehicle is for sale and you plan on allowing others to test drive it, do not submit this request until the vehicle is sold. If you wish to proceed with removing this vehicle you acknowledge that there will be no coverage provided on this policy")}
          confirmLabel={t('I have read and understand')}
        />
      }
      {removeReasonStorage && 
        <Select 
          name="reduce_or_remove" 
          label={t("Would you like to remove the vehicle or reduce coverage?")} 
          choices={removeOrReduce}
        />
      }
      {showMovingTo && (
        <>
          <Text name="moving_to_carrier" label={t("Insurance Carrier")} />
          <Text name="moving_to_policy_number" label={t("Policy Number")} />
        </>
      )}
      {showOtherText && (
        <Text name="remove_reason_other" label={t("Other Reason")} />
      )}
      {reduceCoverageSelected && 
        <>
          <ReduceReason />
          <CoverageWarning />
          <CoverageReview />
        </>
      }
    </fieldset>
  )
}

export default RemoveReason